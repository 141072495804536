// TODO: Delete me when the old sign up survey stuff is deleted
import classNames from "classnames";
import { CDataCheckbox } from "../../../../../../components/CDataCheckbox";
import { CDataTextField } from "../../../../../../components/form/CDataTextField";
import { CDataTypography } from "../../../../../../components/text/CDataTypography";
import { ISignupReasonInfo } from "./signupReasonList";
import { SignupReason } from "src/models/Accounts/SignupReason";

interface ISignupReasonTile {
  reason: ISignupReasonInfo;
  toggle: (reason: ISignupReasonInfo) => void;
  isChecked: boolean;
  otherDetails: string;
  setOtherDetails: (details: string) => void;
}

export const SignupReasonTile = (props: ISignupReasonTile) => {
  const { reason, toggle, isChecked, otherDetails, setOtherDetails } = props;
  const { name, description, iconName, signupReason } = reason;

  const isOtherDetailsAtMax = otherDetails?.length > 250;

  return (
    <div
      className={classNames("signup-reason-tile text-center", {
        "selected-reason-tile": isChecked,
      })}
    >
      <CDataCheckbox
        id={`reasons.${signupReason}.selected`}
        value={signupReason}
        className="p-0 align-self-end"
        onChange={() => toggle(reason)}
      />
      <div className="reason-tile-icon mb-2">
        <i className={`${iconName} fa-solid fa-xl`} />
      </div>
      <CDataTypography
        variant="typography-variant-headline-3"
        color="typography-color-dark-grey"
        className="mb-3"
      >
        {name}
      </CDataTypography>
      {signupReason === SignupReason.Other && isChecked ? (
        <CDataTextField
          name={`reasons.${signupReason}.reasonDetails`}
          className="reason-tile-text-input"
          isInvalid={isOtherDetailsAtMax}
          helperText={
            isOtherDetailsAtMax ? "250 character maxmimum" : undefined
          }
          onChange={(event) => setOtherDetails(event.target.value)}
          placeholder="Let us know what else you expect from Connect Cloud..."
          multiline
          rows={3}
        />
      ) : (
        <CDataTypography
          variant="typography-variant-body-medium"
          color="typography-color-medium-grey"
        >
          {description}
        </CDataTypography>
      )}
    </div>
  );
};
