// TODO: Delete me when the old sign up survey stuff is deleted
import { IAccountSignupReason } from "src/models/Accounts/IAccountSignupReason";
import { cdataFetch } from "../../../../../../api/cdataFetch";

export async function setAccountSignupReasons(reasons: IAccountSignupReason[]) {
  return cdataFetch({
    method: "PUT",
    url: "/signupreasons/account",
    body: reasons,
  });
}
