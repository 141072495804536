// TODO: Delete me when the old sign up survey stuff is deleted and also delete this unnecessary backend SignupReason model
import { SignupReason } from "../../../../../../models/Accounts/SignupReason";

export type ISignupReasonInfo = {
  name: string;
  description: string;
  iconName: string;
  signupReason: SignupReason;
};

export const signupReasonList: ISignupReasonInfo[] = [
  {
    name: "Data Connectivity",
    description:
      "I need to easily access my data  from one or more cloud based connections.",
    iconName: "fa-database",
    signupReason: SignupReason.DataConnectivity,
  },
  {
    name: "Data Blending",
    description:
      "I want to be able to combine data from multiple sources into one view.",
    iconName: "fa-code-compare",
    signupReason: SignupReason.DataBlending,
  },
  {
    name: "Organize Data",
    description:
      "I want to file my data in dedicated workspaces and share curated datasets with others.",
    iconName: "fa-folder",
    signupReason: SignupReason.OrganizeData,
  },
  {
    name: "Data Governance",
    description:
      "I want to control and grant access to my data with custom permission levels for my team.",
    iconName: "fa-file-shield",
    signupReason: SignupReason.DataGovernance,
  },
  {
    name: "ETL",
    description:
      "I want to combine and import data from multiple sources into a data warehouse.",
    iconName: "fa-code-merge",
    signupReason: SignupReason.ETL,
  },
  {
    name: "Other",
    description:
      "I have an entirely different purpose for signing up to trial CData Connect Cloud.",
    iconName: "fa-question",
    signupReason: SignupReason.Other,
  },
];
