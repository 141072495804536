import { useState, useMemo } from "react";
import classnames from "classnames";
import { Badge, Card, Col, Row } from "reactstrap";
import { ListGroup } from "react-bootstrap";
import {
  compareDrivers,
  driverCategories,
} from "../../../../../components/drivers/DriverUtilities";
import { useAPI } from "../../../../../components/useAPI";
import { useFlags } from "flagsmith/react";
import { FeatureId, IPartialDriver } from "../../../../../models";
import FilterGrid from "../../../../../components/FilterGrid";
import { DataSourceCard } from "../../../../connections/components/mapDataSourceCard";
import { isFeatureAllowed } from "../../../../../utility/SubscriptionAddonsFactory";
import { DriverConnectionTile } from "../../../../../components/drivers/DriverConnectionTile";
import { goToDashboard } from "../../../goToDashboard";
import { useAppSelector } from "../../../../../redux/hooks";
import { ISelectedConnection } from "../../InitialSetupContext";
import { useQuery } from "@tanstack/react-query";
import { getAccountSignupReasons } from "src/pages/initialSetup/Setup/Steps/1-SelectConnection/SignupReasonsModal/getAccountSignupReasons";
import { SignupReasonModal } from "./SignupReasonsModal/SignupReasonModal";
import Loader from "src/components/Loader";
import { CDataTypography } from "src/components/text/CDataTypography";
import { SelectedSourcesCard } from "./SelectedSources/SelectedSourcesCard";
import { getSignupReasons } from "src/pages/initialSetup/Survey/getSignupReasons";

export interface ISelectInitialConnectionProps {
  hashKey?: string;
  updateSelectedConnection: (selectedConnection: ISelectedConnection) => void;
  nextStep?: () => void;
  currentStep?: number;
}

export const SelectInitialConnection = (
  props: ISelectInitialConnectionProps,
) => {
  const flagsmith = useFlags(["oracle_oci", "onboarding_survey"]);
  const isOracleOCIEnabled = flagsmith.oracle_oci.enabled;
  const api = useAPI();

  const [activeCategoryFilter, setActiveCategoryFilter] = useState("all");
  const [showSignupReasonModal, setShowSignupReasonModal] = useState(true);

  const allDrivers = useAppSelector((state) => state.driversList?.drivers);
  const availableFeatureIds = useAppSelector(
    (state) => state.subscription?.limits.availableFeatureIds,
  );

  const {
    data: existingAccountSignupReasons,
    isPending: loadingAccountSignupReasons,
  } = useQuery({
    queryKey: ["/signupreasons/account/get"],
    queryFn: () => getAccountSignupReasons(),
    meta: {
      errorMessage:
        "Failed to get account signup reasons due to the following error:",
    },
  });

  const { data: existingSignupReasons, isLoading: loadingSignupReasons } =
    useQuery({
      queryKey: ["/signupreasons/get"],
      queryFn: () => getSignupReasons(),
      meta: {
        errorMessage:
          "Failed to get signup reasons due to the following error:",
      },
    });

  const drivers: IPartialDriver[] = useMemo(() => {
    const filteredDrivers =
      allDrivers?.filter((driverItem) => {
        if (driverItem.hidden) {
          return false;
        }

        if (driverItem.driver === "OracleOci") {
          return isOracleOCIEnabled;
        }

        return true;
      }) ?? [];

    filteredDrivers.sort(compareDrivers);

    return filteredDrivers;
  }, [allDrivers, isOracleOCIEnabled]);

  const driversWithoutAPIConnector = useMemo(() => {
    return drivers.filter((driver) => driver.driver !== "REST");
  }, [drivers]);

  const categories = Object.keys(driverCategories).map((key) => {
    let categoryCount;
    if (driverCategories[key] === "All") {
      categoryCount = driversWithoutAPIConnector.length;
    } else {
      categoryCount = driversWithoutAPIConnector.filter((driver) => {
        return driver.category === key;
      }).length;
    }

    return (
      <ListGroup.Item
        action
        key={key}
        className={classnames({
          active: activeCategoryFilter === key,
        })}
        onClick={() => setActiveCategoryFilter(key)}
      >
        {driverCategories[key]}
        <Badge color="">{categoryCount}</Badge>
      </ListGroup.Item>
    );
  });

  if (loadingAccountSignupReasons || loadingSignupReasons) return <Loader />;

  let connectionCards: DataSourceCard[];
  if (driversWithoutAPIConnector.length > 0) {
    connectionCards = driversWithoutAPIConnector.map((driver, index) => {
      const featureIdToCheck =
        driver.driver === "REST"
          ? FeatureId.APIConnector
          : FeatureId.PremiumDataSources;

      const isPremiumAndUserLacksFeature =
        driver.premium &&
        !isFeatureAllowed(availableFeatureIds ?? [], featureIdToCheck);

      const clientCard = (
        <DriverConnectionTile
          driver={driver}
          onClick={() => goToNextStep(driver)}
          isPremiumAndUserLacksFeature={isPremiumAndUserLacksFeature}
        />
      );
      const connection: DataSourceCard = {
        id: index,
        tile: clientCard,
        driverName: driver.driver!,
        name: driver.niceName!,
        category: driver.category!,
        isPremium: isPremiumAndUserLacksFeature,
      };

      return connection;
    });

    const goToNextStep = (driver: IPartialDriver) => {
      const selectedConnection = {
        driver: driver.driver!,
        niceName: driver.niceName!,
      };

      props.updateSelectedConnection(selectedConnection);
      props.nextStep!();
    };

    const isSignUpReasonModalDisplayed =
      !flagsmith.onboarding_survey.enabled &&
      (!existingAccountSignupReasons ||
        existingAccountSignupReasons?.length === 0);

    return (
      <div className="pages-initialSetup-components-Steps-1-SelectConnection-SelectInitialConnection">
        <h1 className="h3 text-center setup-wizard-title mb-4">
          Setup Your First Connection
        </h1>
        <div className="mb-4 prompt-dashboard-row">
          <CDataTypography
            variant="typography-variant-body-medium"
            color="typography-color-medium-grey"
            className="getting-started-container"
          >
            To get started, select a data source from the list below to begin
            making the most of CData Connect Cloud.
          </CDataTypography>
          <div className="go-to-dashboard-button">
            <button
              onClick={() => {
                window.DD_RUM?.addAction("go to dashboard on step-1 clicked");
                goToDashboard(api.callAPI);
              }}
              className="skip-button"
              data-testid="button-go-to-dashboard"
              data-dd-action-name="go-to-dashboard-on-step-1"
            >
              Go to Dashboard
              <i className="fa-solid fa-arrow-right fa-md ms-2 align-middle" />
            </button>
          </div>
        </div>
        <Row className="data-source-connection-container">
          <Card className="data-source-selector">
            <ListGroup>
              <ListGroup.Item className="fw-bold">Data Sources</ListGroup.Item>
              {categories}
            </ListGroup>
          </Card>
          <Col className="connection-table list-connection-grid">
            <SelectedSourcesCard
              drivers={drivers}
              existingSignupReasons={existingSignupReasons}
              goToNextStep={goToNextStep}
            />
            <FilterGrid
              categories={driverCategories}
              selectedCategory={activeCategoryFilter}
              tiles={connectionCards}
              tileTypes="Connections"
            />
          </Col>
        </Row>
        {isSignUpReasonModalDisplayed && (
          <SignupReasonModal
            showModal={showSignupReasonModal}
            setShowModal={setShowSignupReasonModal}
          />
        )}
      </div>
    );
  }
};
