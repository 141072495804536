import { CDataCard } from "src/components/card/CDataCard";
import { CDataTypography } from "src/components/text/CDataTypography";
import { IPartialDriver } from "src/models";
import { getBaseIconUrl } from "src/components/drivers/DriverIconFactory";
import classnames from "classnames";

interface SelectedSourcesTileProps {
  driver: IPartialDriver;
  goToNextStep: (driver: IPartialDriver) => void;
}

export function SelectedSourcesTile(props: SelectedSourcesTileProps) {
  const { driver, goToNextStep } = props;

  const cardClass = classnames("selected-source-tile mb-2", {
    "tile-disabled": driver.driver === "REST",
  });

  return (
    <CDataCard
      className={cardClass}
      onClick={() =>
        driver.driver !== "REST" ? goToNextStep(driver) : undefined
      }
    >
      <CDataCard className="selected-source-icon-card mb-2">
        <img
          src={`${getBaseIconUrl()}/${driver.driver}.svg`}
          className="selected-source-icon"
          alt={`${driver.driver} icon`}
        />
      </CDataCard>
      <CDataTypography
        variant="typography-variant-body-medium"
        color="typography-color-text-muted"
        className="driver-name pb-2"
      >
        {driver.niceName}
      </CDataTypography>
    </CDataCard>
  );
}
