import { CDataCard } from "src/components/card/CDataCard";
import { CDataCardBody } from "src/components/card/CDataCardBody";
import { CDataTypography } from "src/components/text/CDataTypography";
import { IPartialDriver } from "src/models";
import { CDataTooltip, CDataTooltipType } from "src/components/CDataTooltip";
import { SurveyForm } from "src/pages/initialSetup/Survey/SurveyWizard";
import { IUserSignupReason } from "src/models/Accounts/IUserSignupReason";
import { compareDrivers } from "src/components/drivers/DriverUtilities";
import { SelectedSourcesTile } from "./SelectedSourceTile";

interface SelectedSourcesCardProps {
  drivers: IPartialDriver[];
  existingSignupReasons: IUserSignupReason | undefined;
  goToNextStep: (driver: IPartialDriver) => void;
}

export function SelectedSourcesCard(props: SelectedSourcesCardProps) {
  const { drivers, existingSignupReasons, goToNextStep } = props;

  if (!existingSignupReasons || !existingSignupReasons.surveyForm)
    return undefined;

  const surveyForm: SurveyForm = JSON.parse(existingSignupReasons.surveyForm);

  if (surveyForm && surveyForm.selectedDrivers.length === 0) {
    return undefined;
  }

  const getSortedDrivers = () => {
    const newDrivers = [...drivers];

    newDrivers.sort(compareDrivers);

    return newDrivers;
  };

  const sortedDrivers = getSortedDrivers();
  const selectedDrivers = sortedDrivers.filter((d) =>
    surveyForm.selectedDrivers.includes(d.driver!),
  );

  const selectedSources = selectedDrivers.map((driver) => {
    if (driver.driver === "REST") {
      return (
        <CDataTooltip
          key={driver.driver}
          type={CDataTooltipType.Dark}
          title="To begin setup of the API Connector, click “Go to Dashboard” and navigate to Sources."
        >
          <div id={driver.driver}>
            <SelectedSourcesTile driver={driver} goToNextStep={goToNextStep} />
          </div>
        </CDataTooltip>
      );
    }

    return (
      <SelectedSourcesTile
        key={driver.driver}
        driver={driver}
        goToNextStep={goToNextStep}
      />
    );
  });

  return (
    <CDataCard className="selected-sources-card mb-0">
      <CDataCardBody className="d-flex flex-column p-3 pb-0">
        <CDataCard className="selected-sources-tiles-card mb-0">
          <CDataCardBody className="d-flex flex-column p-3 pb-2">
            <CDataTypography
              variant="typography-variant-headline-5"
              className="pb-2"
            >
              Your Selected Sources:
            </CDataTypography>
            <div className="selected-sources-row">{selectedSources}</div>
          </CDataCardBody>
        </CDataCard>
      </CDataCardBody>
    </CDataCard>
  );
}
